// const $ = require('jquery');
import * as $ from 'jquery';
// export for others scripts to use
window.$ = $;
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import flatpickr from "flatpickr";
import Flickity from 'flickity';
import fullscreen from 'flickity-fullscreen';

// Date/Time Picker for Sales Portal
const initFlatpickr = () => {
    flatpickr(".flatpickr-timePicker", {
        enableTime: false,
        dateFormat: "Y-m-d",
    });
}

document.addEventListener('DOMContentLoaded', () => {
    console.log('👋 hi');

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if (isIE11) {
        document.getElementsByTagName("BODY")[0].classList.add('ie11');
    }

    // Init lazy load images sizes
    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.expand = 300; // default 360-500
    lazySizesConfig.expFactor = 1.5; // default: 1.7

    initFlatpickr();

    // Search button aria label toggle
    const headerSearchBtnEl = document.getElementById('header-search-btn');
    if (headerSearchBtnEl) {
        headerSearchBtnEl.addEventListener('click', function (e) {
            if (this.getAttribute('aria-label') === 'Open QSI search field') {
                headerSearchBtnEl.setAttribute('aria-label', 'Close QSI search field');
            } else {
                headerSearchBtnEl.setAttribute('aria-label', 'Open QSI search field');
            }
        });
    }

    // Global mobile nav toggle button
    const navToggle = document.querySelector('.navbar-toggler');
    if (navToggle) {
        navToggle.addEventListener('click', () => {
            if (navToggle.getAttribute('aria-label') === 'Open navigation') {
                navToggle.setAttribute('aria-label', 'Close navigation');
            } else {
                navToggle.setAttribute('aria-label', 'Open navigation');
            }
            if (navToggle.getAttribute('aria-expanded') === 'false') {
                navToggle.setAttribute('aria-expanded', 'true');
            } else {
                navToggle.setAttribute('aria-expanded', 'false');
            }
            const collapsedNav = document.getElementById('navbarSupportedContent');
            collapsedNav.classList.toggle('expanded');
        });
    }

    function countySelectionOptions (countySelectOptions, selectedOptionDataValue, selectedCountyOption) {
        // let countyFipsArray = [];
        console.log('fired county select');
        if (countySelectOptions) {
            for (let i = 0; i < countySelectOptions.length; i++) {
                let countyOptionDataValue = countySelectOptions[i].getAttribute('data-value');
                if (countyOptionDataValue != '') {
                    let countyFipsTwoDigits = countyOptionDataValue.substring(0, 2);
                    // countyFipsArray.push(countyFipsTwoDigits);
                    if (countyFipsTwoDigits == selectedOptionDataValue) {
                        countySelectOptions[i].removeAttribute('disabled');
                    } else {
                        countySelectOptions[i].setAttribute('disabled', 'disabled');
                    }
                }
            }
        }
    }

    const stateSelect = document.querySelector('#form-input-state');
    const countyFormGroup = document.querySelector('.county-form-group');
    const countySelect = document.querySelector('#form-input-county');
    const countySelectOptions = document.querySelectorAll('#form-input-county option');
    if (stateSelect) {
        if (stateSelect.value !== '') {
            const selectedStateOption = stateSelect.selectedIndex;
            const selectedCountyOption = countySelect.selectedIndex;
            const selectedStateFipsCode = document.querySelectorAll('#form-input-state option')[selectedStateOption].getAttribute('data-value');
            countySelectionOptions(countySelectOptions, selectedStateFipsCode, selectedCountyOption);
        }
        stateSelect.addEventListener('change', function(e) {
            let targetOption = e.target.options;
            let selectedTargetOption = targetOption.selectedIndex;
            let selectedOptionDataValue = document.querySelectorAll('#form-input-state option')[selectedTargetOption].getAttribute('data-value');
            countySelect.value = '';
            countySelectionOptions(countySelectOptions, selectedOptionDataValue, null);
        });
    }

    window.$('[data-toggle="tooltip"]').tooltip();
});

